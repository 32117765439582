@import 'colors';
.mat-mdc-table {
  width: 100%;
  .mat-mdc-row {
    height: 70px;
    &:not(.disabled-row):hover {
      background-color: #f4f6f8!important;
    }
    &.selected {
      background-color: #f4f6f8!important;
    }
  }

 th, .mat-mdc-header-cell>*,.mat-sort-header-content{
    color: $primary-grey-color;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
   text-align: left;
    font-family: 'Montserrat', sans-serif !important;
 }
  .mat-mdc-cell {
    color: $primary-grey-color;
    font-size: 14px;
    line-height: 20px;
    padding-right: 5px !important;
  }
  .custom-icon {
    color: $primary-grey-color;
    font-size: 18px;
    margin-right: 20px;
  }
  .mat-icon {
    color: $primary-grey-color;
    font-size: 18px;
  }
}
