// Color variables

$black-color: #000;
$half-black: rgba(0, 0, 0, 0.54);
$white-color: #fff;
$primary-grey-color: #263238;
$light-grey-color: #546e7a;
$medium-grey: rgba(109, 109, 102, 1);
$paginator-arrow-disabled-gray: #bdbdbd;
$indigo-color: rgba(15, 65, 105, 1);
$general-grey-bg: #f4f6f8;
$general-light-blue-bg: rgba(244, 248, 255, 1);
$deep-blue-color: rgba(15, 65, 105, 1);
$light-grey-bg: #f5f5f5;
$purple-color: #5850ec;
$turquoise-color: rgba(24, 161, 174, 1);
$light-blue: #2196f3;
$dark-blue: #0f4169;
$error-color: #e31b0c;
$light-red: #f44336;
$green-color: #4caf50;
$dark-green-color: #3b873e;
$light-green-bg: #e8f5e9;
$orange-color: #f57c00;
$yellow-color: #ff9800;
$red-color: #e53935;
$border-color: #e0e0e0;
