@import "colors";
.mat-mdc-paginator-container{
  font-size: 12px;
  color: $light-grey-color !important;

.mat-paginator-icon {
  width: 22px !important;
  fill: $primary-grey-color !important;
}
button[disabled='true'] .mat-mdc-paginator-icon {
  fill: $paginator-arrow-disabled-gray !important;
}
.mat-mdc-paginator-page-size-select {
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    max-width: 42px;
    border: none !important;
  }
}

}
