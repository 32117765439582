@import 'colors';

.custom-badge {
  display: inline-flex; /* Ensure badge aligns properly with content */
  align-items: center;
    position: relative;
    top: 0; /* Remove unnecessary offset */
    margin-left: 10px;
    padding: 0 4px; /* Adjust padding for better alignment */
    min-width: 14px;
    height: auto; /* Ensure height is consistent */
    line-height: 1; /* Make sure text stays aligned */
    border-radius: 0;
    max-width: 270px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif !important;



  &.badge-sm {
    font-size: 12px;
  }
}

.custom-badge-warn {
  @extend .custom-badge;

    background-color: #fff3e0;
    color: $orange-color;

}

.custom-badge-danger {
  @extend .custom-badge;

    background-color: #feebee;
    color: $red-color;

}
