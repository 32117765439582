@import 'colors';
::ng-deep .mat-mdc-form-field,  .mat-mdc-floating-label{
  font-family:Montserrat,sans-serif!important;
  letter-spacing: 0.15px;
}
.custom-search-field{
  .mdc-text-field--filled{
    border-bottom: 1px solid $light-grey-bg;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;

  }

  // Manipulate background color on hover and focus
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;

  }

  .mdc-line-ripple{
    display: none;
  }
}

.mat-mdc-form-field-icon-suffix:not(:has(.mat-icon)){

  padding-right: 8px;
  padding-left: 8px;
}

.mat-form-field-readonly {
  opacity: 0.7;

  .mat-mdc-text-field-wrapper {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.custom-select-like-dropdown{
  cursor: pointer;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: 600;
    cursor: pointer;

  }
  .mat-mdc-text-field-wrapper{
    padding: 0px;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: 600;
    cursor: pointer;
  }

  .mdc-floating-label{
    color: #263238!important;
    cursor: pointer;
  }

  .mdc-line-ripple{
    display: none;
    cursor: pointer;
  }
}


.custom-select-like-dropdown.ubuntu{
  cursor: pointer;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    font-family: Ubuntu, sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 0.1px;
    font-weight: normal;
    cursor: pointer;

  }

  .mat-mdc-form-field-focus-overlay {
    font-family: Ubuntu, sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 0.1px;
    font-weight: normal;
    cursor: pointer;
  }


}


.custom-mat-suffix-next-to-field{
  .mat-mdc-form-field-icon-suffix{
    position: relative;
    left : 55px;
    font-size: 24px;
  }
}


.pv-field-white-background{
  /* Font color */
  input>* {
    color: black;
  }
  .mat-mdc-text-field-wrapper{
    background: white;
  }

  .mdc-text-field{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
    /* Font color */
  input.mat-input-element ,.mdc-text-field__input{
    color: black;
  }
  .mat-mdc-form-field-flex {
    width: 170px!important;
    height: 50px!important;
    font-size: 16px!important;
    //background: rgba(255, 255, 255, 0.07);

  }

  .mdc-line-ripple,.mat-mdc-form-field-subscript-wrapper{
    display: none;
    cursor: pointer;

  }


}




.pv-sm-field{
  .mat-mdc-form-field-flex {
    height: 50px!important;
    font-size: 14px!important;
  }
}


.pv-field-white-border{
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)  ,.mat-mdc-form-field-flex ,&.mat-mdc-form-field,.mat-mdc-form-field-flex,.mdc-floating-label{
    color: white !important;
    border-color: white!important;
  }

  .mat-mdc-form-field-focus-overlay,.mat-mdc-form-field-infix,.mat-mdc-input-element,.mdc-notched-outline__notch,.mdc-notched-outline {
    border-color: white!important;
  }
  .mdc-floating-label>*{
    color: white!important;
  }

  .mdc-notched-outline > * {
    border-color: white !important;
    color: white;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder{
    color: white;
  }
}



.fit-content {
  width: auto;
  display: inline-block;
  .mat-mdc-form-field-flex {
    display: inline-block;
  }

  .mat-mdc-select {
    width: auto;
  }
  .mat-mdc-select-value-text {
    white-space: nowrap;
  }

  .mat-mdc-select-trigger {
    width: auto;
  }

}
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  width: auto !important;
}
