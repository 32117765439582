@import "colors";
.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

 .modal-header {
    position: relative;
    padding: 5px 15px 15px 15px;
    border-bottom: 1px solid $light-grey-bg;
   word-wrap: break-word !important;

   .mat-mdc-dialog-title {
     width: 90%;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05px;
      font-family: 'Montserrat', sans-serif !important;
      color: #263238;
     word-wrap: break-word !important;
     white-space: pre-wrap !important;
    }
    .close {
      float: right;
      margin-top: 10px;
      color: #263238;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }



