@import "colors";
.pv-button-secondary .mdc-button--outlined{

    .mdc-button__label{
      color: #263238!important;

  }

}

.mdc-button{
  .mdc-button__label {
    text-transform: uppercase;
    font-family: 'Montserrat', serif;

    font-weight: 500;
  }


}
.pv-button-grey.mdc-button {
  --mdc-protected-button-container-color: #F5F5F5;
  background-color: #F5F5F5!important;

}


.pv-button-stroke{
  .mdc-button--outlined{
    color: #0f4169;
  }
}

.mat-mdc-raised-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mat-mdc-raised-button .mat-icon {
  margin-top: 3px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.mat-mdc-raised-button .mat-spinner {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-right: 8px;
}

